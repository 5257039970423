import React from 'react'
import { Link } from '@elements'
import LogoWhite from '@images/logo-landscape-white.svg'

export const MobileFooter = () => {
  return (
    <footer className="py-10 bg-blue-400 text-white">
      <div className="container">
        <div className="flex flex-col">
          <div className="flex justify-center sm:justify-start">
            <Link to="/">
              <img src={LogoWhite} alt="Updike Bathroom logo" />
            </Link>
            <small className="hidden">&copy; Updike Construction {new Date().getFullYear()}</small>
          </div>
          <div className="mt-8 uppercase text-lg flex">
            <div className="w-1/2 flex flex-col items-center sm:items-start">
              <Link className="mt-4" to="/">
                home
              </Link>
              <Link className="mt-4" to="/about/">
                about
              </Link>
              <Link className="mt-4" to="/services/">
                services
              </Link>
            </div>
            <div className="w-1/2 flex flex-col items-center sm:items-start ">
              <Link className="mt-4" to="/gallery/">
                gallery
              </Link>
              <Link className="mt-4" to="/testimonials/">
                testimonials
              </Link>
              <Link className="mt-4" to="/contact/">
                contact
              </Link>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="mt-10 w-full sm:w-1/2 flex flex-col text-center sm:text-left">
              <h3 className="uppercase text-lg font-bold">location</h3>
              <Link className="inline-block mt-4">
                7494 Madison Ave <br /> Indianapolis, IN 46227
              </Link>
            </div>
            <div className="mt-10 w-full sm:w-1/2 flex flex-col text-center sm:text-left">
              <h3 className="uppercase text-lg font-bold">contact</h3>
              <div className="mt-4">
                <a className="block" href="tel:317-786-0360">
                  317-786-0360
                </a>
                <a className="block" href="mailto:updikebathroom@comcast.net">
                  updikebathroom@comcast.net
                </a>
              </div>
            </div>
          </div>
          <div className="mt-10 text-center sm:text-left">
            <small>&copy; Updike Construction {new Date().getFullYear()}</small>
          </div>
        </div>
      </div>
    </footer>
  )
}
