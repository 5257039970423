import React from 'react'
import { Link } from '@elements'
import LogoWhite from '@images/logo-landscape-white.svg'

export const DesktopFooter = () => {
  return (
    <footer className="py-16 bg-blue-400 text-white">
      <div className="container">
        <div className="flex items-center justify-between">
          <div className="">
            <Link to="/">
              <img src={LogoWhite} alt="Updike Bathroom logo" />
            </Link>
            <small className="inline-block mt-8 text-base">
              &copy; Updike Construction {new Date().getFullYear()}
            </small>
          </div>
          <div className="uppercase flex text-base">
            <div className="w-1/2">
              <Link className="block" to="/">
                home
              </Link>
              <Link className="block mt-2" to="/about/">
                about
              </Link>
              <Link className="block mt-2" to="/services/">
                services
              </Link>
            </div>
            <div className="w-1/2 ml-8">
              <Link className="block" to="/gallery/">
                gallery
              </Link>
              <Link className="block mt-2" to="/testimonials/">
                testimonials
              </Link>
              <Link className="block mt-2" to="/contact/">
                contact
              </Link>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 text-left">
              <h3 className="uppercase font-bold">location</h3>
              <Link className="inline-block mt-4 text-base">
                7494 Madison Ave <br /> Indianapolis, IN 46227
              </Link>
            </div>
            <div className="w-1/2 ml-10 text-left">
              <h3 className="uppercase font-bold">contact</h3>
              <div className="mt-4">
                <a className="block text-base" href="tel:317-786-0360">
                  317-786-0360
                </a>
                <a
                  className="block text-base"
                  href="mailto:updikebathroom@comcast.net"
                >
                  updikebathroom@comcast.net
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
