import React from 'react'
import { Link } from '@elements'
import LogoWhite from '@images/logo-landscape-white.svg'

export const TabletFooter = () => {
  return (
    <footer className="py-12 bg-blue-400 text-white">
      <div className="container">
        {/* <div className="uppercase flex justify-between">
          <Link className="" to="/">
            home
          </Link>
          <Link className="" to="/about/">
            about
          </Link>
          <Link className="" to="/services/">
            services  
          </Link>
          <Link className="" to="/gallery/">
            gallery
          </Link>
          <Link className="" to="/testimonials/">
            testimonials
          </Link>
          <Link className="" to="/contact/">
            contact
          </Link>
        </div> */}
        <div className=" flex items-center justify-between">
          <div className="w-48 md:w-64">
            <Link to="/">
              <img src={LogoWhite} alt="Updike Bathroom logo" />
            </Link>
            <small className="inline-block mt-6 text-xs md:text-sm lg:text-base">
              &copy; Updike Construction {new Date().getFullYear()}
            </small>
          </div>
          <div className="flex lg:pr-16">
            <div className="w-1/2 text-left">
              <h3 className="uppercase font-bold text-sm">location</h3>
              <Link className="inline-block mt-4 text-xs md:text-sm lg:text-base">
                7494 Madison Ave <br /> Indianapolis, IN 46227
              </Link>
            </div>
            <div className="w-1/2 text-left ml-8 lg:ml-16">
              <h3 className="uppercase font-bold text-sm">contact</h3>
              <div className="mt-4">
                <a className="block text-xs md:text-sm lg:text-base" href="tel:317-786-0360">
                  317-786-0360
                </a>
                <a
                  className="block text-xs md:text-sm lg:text-base"
                  href="mailto:updikebathroom@comcast.net"
                >
                  updikebathroom@comcast.net
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
