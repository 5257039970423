import React from 'react'
import { Link } from '@elements'
import { useWindowWidth } from '@hooks'
import { MobileNavigation, DesktopNavigation } from './Navigation'
import Logo from '@images/logo.svg'

export const Header = () => {
  const viewportWidth = useWindowWidth()
  return (
    <header className="bg-white">
      <div className="container py-6 sm:py-8 flex items-center justify-between">
        <Link to="/">
          <img
            src={Logo}
            alt="Updike Bathroom Logo"
            className="h-auto w-40 sm:w-48"
          />
        </Link>
        {viewportWidth >= 768 ? <DesktopNavigation /> : <MobileNavigation />}
      </div>
    </header>
  )
}
