import React, { createContext, useContext } from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
import { Header } from './Header'
import { SlidingMenu } from './Navigation'
import { MobileFooter, TabletFooter, DesktopFooter } from './Footer'
import { useToggle, useSessionStorage } from '@hooks'
import { Link } from '@elements'

export const AppContext = createContext({
  isMenuOpen: false,
})

export const Layout = ({ children }) => {
  const { isToggled, toggle } = useToggle(false)
  const { value: covidOpen, setValue: setCovidOpen } = useSessionStorage(
    'open',
    'true'
  )

  return (
    <AppContext.Provider value={{ isMenuOpen: isToggled, toggleMenu: toggle }}>
      <SimpleReactLightbox>
        <div className="antialiased font-body relative">
          <Header />
          <SlidingMenu />
          <main className="bg-white">{children}</main>
          {covidOpen === 'true' ? (
            <div className="bottom-0 left-0 right-0 fixed bg-blue-100 z-50">
              <div className="container relative p-8 max-w-2xl">
                <button
                  onClick={() => setCovidOpen('false')}
                  className="top-0 right-0 absolute p-3 text-xl text-gray-900 font-bold"
                >
                  &times;
                </button>
                <Link to="/covid-19/" className="block text-center underline">
                  Read our COVID-19 Statement
                </Link>
              </div>
            </div>
          ) : (
            <div className="sm:hidden fixed inset-x-0 bottom-0 z-10 bg-blue-200 h-16 flex items-center justify-center">
              <a
                href="tel:3177860360"
                className=" text-white font-bold uppercase"
              >
                Call Now
              </a>
            </div>
          )}

          <div className="sm:hidden">
            <MobileFooter />
          </div>
          <div className="hidden sm:block xl:hidden">
            <TabletFooter />
          </div>
          <div className="hidden xl:block">
            <DesktopFooter />
          </div>
        </div>
      </SimpleReactLightbox>
    </AppContext.Provider>
  )
}

export const useAppState = () => useContext(AppContext)
