import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'
import { useAppState } from '../Layout'
import { useScrollFreeze } from '@hooks'
import { Link } from '@elements'
import Logo from '@images/logo.svg'
import Close from '@images/close.svg'

export const SlidingMenu = () => {
  const { isMenuOpen } = useAppState()
  return <AnimatePresence>{isMenuOpen && <Navigation />}</AnimatePresence>
}

const Navigation = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menu
        }
      }
    }
  `)

  const menuItems = data.site.siteMetadata.menu
  const { toggleMenu } = useAppState()
  useScrollFreeze()
  return (
    <motion.nav
      key="MobileNavigation"
      initial={{ x: `100%`, opacity: 0 }}
      animate={{ x: `0%`, opacity: 1 }}
      exit={{ x: `100%`, opacity: 0 }}
      transition={{ ease: 'easeInOut' }}
      className="fixed inset-0 z-50 bg-white"
    >
      <div className="container py-6 sm:py-8 ">
        <div className="flex items-center justify-between">
          <Link to="/">
            <img
              src={Logo}
              alt="Updike Bathroom Logo"
              className="h-auto w-32 sm:w-40"
            />
          </Link>
          <button onClick={toggleMenu}>
            <img src={Close} alt="Close Menu Icon" />
          </button>
        </div>
        <div className="mt-16 flex flex-col justify-center items-center text-center text-blue-400 text-2xl font-bold uppercase">
          <Link
            to={`/`}
            className="inline-block cursor-pointer hover:text-blue-200 transition duration-150"
            activeClassName="border-b-2 border-blue-300 pb-1 text-blue-300"
          >
            home
          </Link>
          {menuItems.map(menuItem => (
            <Link
              key={menuItem}
              to={`/${menuItem}/`}
              className="mt-3 inline-block cursor-pointer hover:text-blue-200 transition duration-150"
              activeClassName="border-b-2 border-blue-200 pb-1 text-blue-200"
            >
              {menuItem}
            </Link>
          ))}
        </div>
      </div>
    </motion.nav>
  )
}
