import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from '@elements'

export const DesktopNavigation = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menu
        }
      }
    }
  `)

  const menuItems = data.site.siteMetadata.menu

  return (
    <nav className="">
      <div className="container">
        {menuItems.map(menuItem => (
          <Link
            key={menuItem}
            to={`/${menuItem}/`}
            className="ml-10 lg:ml-16 cursor-pointer uppercase text-gray-400 font-bold text-sm hover:text-blue-200 transition duration-150"
            activeClassName="border-b-2 border-blue-200 pb-1 text-blue-200"
          >
            {menuItem}
          </Link>
        ))}
      </div>
    </nav>
  )
}
