import React from 'react'
import { useAppState } from '../Layout'

export const MobileNavigation = () => {
  const { toggleMenu } = useAppState()
  return (
    <nav className="flex items-center">
      <button
        onClick={toggleMenu}
        className="inline-block h-10 w-10 text-blue-300"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className=""
        >
          <path
            className="fill-current"
            d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
          ></path>
        </svg>
      </button>
    </nav>
  )
}
